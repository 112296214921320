// export const environment = {
//   apiUrl: 'https://estacaodoolhoapi.azurewebsites.net',
// };

// const homol_config: any = {
//   baseUrl: 'https://estacaodoolhoapihom.azurewebsites.net',
// };

export const environment = {
  //  apiUrl: "https://estacaodoolhoapihom.azurewebsites.net",
   apiUrl: "https://estacaodoolhoapiprod.azurewebsites.net",
  // apiUrl: 'https://estacaodoolhoapi.azurewebsites.net'
};
